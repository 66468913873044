import React from "react";
import "./GetApp.css";

const GetApp = () => {
  return (
    <div className="body">
      <h1 className="main-heading">Am besten in der App.</h1>
      <a
        href="https://testflight.apple.com/join/ySwJwe4W"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="./appStore/appStore_en.svg"
          alt="App Store"
          className="app-store"
        />
      </a>
      <a
        href="https://play.google.com/store/apps/details?id=com.m8tec.Evention"
        target="_blank"
        rel="noreferrer"
      >
        <img
          src="./playStore/playStore_en.svg"
          alt="Google Play Store"
          className="app-store"
        />
      </a>
    </div>
  );
};

export default GetApp;
