import React from "react";

const TermsOfService = () => {
  return (
    <div>
      <h2>Terms of Service</h2>
      <p>Here goes the content of the terms of service...</p>
    </div>
  );
};

export default TermsOfService;
