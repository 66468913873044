import React from "react";
import "./Home.css";

const Home = () => {
  return (
    <header className="header">
      <h1 className="main-heading">Finde heraus, was los ist</h1>
    </header>
  );
};

export default Home;
