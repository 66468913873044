// src/App.js
import React from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Link,
  useNavigate,
} from "react-router-dom";
import "./App.css"; // Import your CSS file
import PrivacyPolicy from "./pages/PrivacyPolicy"; // Privacy Policy component
import TermsOfService from "./pages/TermsOfService"; // Terms of Service component
import Home from "./pages/Home/Home"; // Home component
import GetApp from "./pages/GetApp/GetApp"; // Event component

function App() {
  return (
    <Router>
      <div className="app">
        {/* Header Section */}
        <Header />

        {/* Main Content with Margin */}
        <div className="content-container">
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/terms-of-service" element={<TermsOfService />} />
            <Route path="/get-app" element={<GetApp />} />

            {/* Catch mobile universal/deep links when app is not installed yet */}
            <Route path="/event" element={<GetApp />} />
            <Route path="/profile" element={<GetApp />} />
            <Route path="/asset" element={<GetApp />} />
          </Routes>
        </div>

        {/* Bottom Navigation Bar */}
        <footer className="bottom-bar">
          <Link to="/privacy-policy" className="bottom-link">
            Datenschutzrichtlinie
          </Link>
          <Link to="/terms-of-service" className="bottom-link">
            Nutzungsbedingungen
          </Link>
        </footer>
      </div>
    </Router>
  );
}

const Header = () => {
  const navigate = useNavigate();

  return (
    <header className="app-header">
      <div className="header-left" onClick={() => navigate("/")}>
        <img
          src="/logo/bare.webp"
          alt="Evention Logo"
          className="header-logo"
        />
        <h1 className="header-title">Evention</h1>
      </div>
      <div className="header-right">
        <button className="get-app-button" onClick={() => navigate("/get-app")}>
          Hol dir die App
        </button>
      </div>
    </header>
  );
};

export default App;
